import React from 'react'
import axios, { AxiosInstance } from 'axios'
import { getPermissions, PERMISSIONS, ROLES, userProgram } from './role'

export interface UserProps {
    role: ROLES[]
    permissions: PERMISSIONS[]
    userPrograms: userProgram[]
    userAlias: string | undefined
}

export interface AppContextProps {
    apiClient: AxiosInstance
    userAlias: string | undefined
    userProps: UserProps
}

export const AppContext = React.createContext<AppContextProps>({
    apiClient: axios.create(),
    userAlias: '',
    userProps: {
        role: [ROLES.VIEWER],
        userAlias: '',
        userPrograms: [],
        permissions: [getPermissions[ROLES.VIEWER]],
    },
})
export const useAppContext = () => React.useContext(AppContext)
