import React, { useState, useEffect } from 'react'
import {
    Box,
    SpaceBetween,
    Toggle,
    DatePicker,
    FormField,
    Select,
} from '@amzn/awsui-components-react'
import Textarea from '@amzn/awsui-components-react/polaris/textarea'
import ModalTemplate from '../reusable/ModalTemplate'
import { ModalModes } from '../../Constant'
import { useAppContext } from '../../../context'
import {
    BANNER_TYPE,
    BANNER_TYPE_OPTIONS,
    convertMilliToSecEpochTime,
    EMPTY_BANNER_EXPIRATION_DATE,
    EMPTY_BANNER_MESSAGE,
    getDefaultExpirationDate,
    isDateEnabled,
} from './BannerUtil'
import { generateId } from '../../common/Util'
// todo: uncomment once tinymce rich text editor is implemented
// import '../../../tinymcePremium/pluginImports'
// import { Editor } from '@tinymce/tinymce-react'
// import {
//     DEFAULT_FONT_FAMILY_FORMATS,
//     DEFAULT_MENU,
//     DEFAULT_MENU_BAR,
//     DEFAULT_PLUGINS,
//     DEFAULT_TOOLBAR,
// } from '../../../tinymcePremium/tinyPlugInDefaults'
const CreateEditBannerModal = ({
    mode,
    modalVisible,
    setModalVisible,
    selectedBanner,
    setSelectedBanner,
    getBanners,
    bannerAlerts,
    setBannerAlerts,
    dismissAlerts,
}) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [isRemovable, setIsRemovable] = useState<boolean>(true)
    const [bannerExpirationDate, setBannerExpirationDate] = useState<string>(
        EMPTY_BANNER_EXPIRATION_DATE,
    )
    const [bannerType, setBannerType] = useState<any>(BANNER_TYPE_OPTIONS[0])
    const [bannerMessage, setBannerMessage] = useState<string>(EMPTY_BANNER_MESSAGE)

    const validateBannerPayload = (payload) => {
        return (
            payload.message !== EMPTY_BANNER_MESSAGE && payload.banner_type !== BANNER_TYPE.NOT_SET
        )
    }

    const generateBannerPayload = () => {
        const bannerId =
            selectedBanner && mode === ModalModes.EDIT
                ? selectedBanner.value.split('#')[1]
                : generateId()
        const date = bannerExpirationDate ? bannerExpirationDate : getDefaultExpirationDate()
        const dateSplit: string[] = date.split('-')
        return {
            banner_id: bannerId,
            ttl: convertMilliToSecEpochTime(
                new Date(
                    parseInt(dateSplit[0]),
                    parseInt(dateSplit[1]) - 1,
                    parseInt(dateSplit[2]),
                ).valueOf(),
            ),
            banner_type: bannerType.value,
            message: bannerMessage,
            removable: isRemovable,
        }
    }
    const onBannerSubmitHandler = () => {
        const payload = generateBannerPayload()
        createBanner(payload)
            .then(() => {
                getBanners()
                addBannerAlert(payload)
            })
            .catch((error) => {
                console.error(error)
            })
        setModalVisible(false)
        setBannerExpirationDate(EMPTY_BANNER_EXPIRATION_DATE)
        setBannerType(BANNER_TYPE_OPTIONS[0])
        setBannerMessage('')
        setIsRemovable(true)
    }

    const addBannerAlert = (banner) => {
        const today = convertMilliToSecEpochTime(Date.now().valueOf())
        const existingBanner = bannerAlerts.find((item) => item.id === `falcon#${banner.banner_id}`)
        if (banner.ttl >= today) {
            const id = `falcon#${banner.banner_id}`
            const newAlert = {
                type: banner.banner_type,
                content: banner.message,
                dismissible: banner.removable,
                id: id,
                onDismiss: () => {
                    dismissAlerts(id, bannerAlerts)
                },
            }
            setBannerAlerts(
                existingBanner
                    ? [newAlert, ...bannerAlerts.filter((item) => item.id !== existingBanner.id)]
                    : [newAlert, ...bannerAlerts],
            )
        }
    }

    const createBanner = (payload) => {
        return apiClient.put(`/banner/${payload.banner_id}`, payload)
    }

    useEffect(() => {
        if (!modalVisible) {
            setBannerExpirationDate(EMPTY_BANNER_EXPIRATION_DATE)
            setBannerType(BANNER_TYPE_OPTIONS[0])
            setBannerMessage(EMPTY_BANNER_MESSAGE)
            setIsRemovable(true)
            return
        }
        if (selectedBanner) {
            setBannerType(
                BANNER_TYPE_OPTIONS.find((option) => option.value === selectedBanner.banner_type),
            )
            const selectedDate = new Date(selectedBanner.ttl * 1000).toISOString().split('-')
            const month = selectedDate[1]
            const day = selectedDate[2].split('T')[0]
            setBannerExpirationDate(`${selectedDate[0]}-${month}-${day}`)
            setBannerMessage(selectedBanner.message)
            setIsRemovable(selectedBanner.removable)
        }
    }, [modalVisible])

    return (
        <ModalTemplate
            modalVisible={modalVisible}
            onModalVisibleChange={setModalVisible}
            title={mode === ModalModes.CREATE ? 'Create Banner' : 'Edit Banner'}
            body={
                <SpaceBetween size='m' direction={'vertical'}>
                    <FormField label='Message'>
                        <Box>
                            {/*TODO: uncomment after tinymce text editor is implemented */}
                            {/*<Editor*/}
                            {/*    value={bannerMessage}*/}
                            {/*    onEditorChange={(value: string) => setBannerMessage(value)}*/}
                            {/*    onInit={(evt, editor) => {*/}
                            {/*        editor.focus()*/}
                            {/*    }}*/}
                            {/*    init={{
                            {/*    }}*/}
                            {/*/>*/}
                            <Textarea
                                onChange={({ detail }) => setBannerMessage(detail.value)}
                                value={bannerMessage}
                                placeholder='Enter banner message ...'
                            />
                        </Box>
                    </FormField>
                    <FormField label='Type'>
                        <Box>
                            <Select
                                selectedOption={bannerType}
                                onChange={({ detail }) => setBannerType(detail.selectedOption)}
                                options={BANNER_TYPE_OPTIONS}
                            />
                        </Box>
                    </FormField>
                    <SpaceBetween size='s' direction='horizontal'>
                        <FormField label={'Is Banner Removable?'}></FormField>
                        <Toggle
                            onChange={({ detail }) => setIsRemovable(detail.checked)}
                            checked={isRemovable}
                        ></Toggle>
                    </SpaceBetween>
                    <FormField label='Banner Expiration Date'>
                        <DatePicker
                            isDateEnabled={(date: Date) => {
                                return isDateEnabled(date)
                            }}
                            onChange={({ detail }) => setBannerExpirationDate(detail.value)}
                            value={bannerExpirationDate}
                            openCalendarAriaLabel={(selectedDate) =>
                                'Choose Banner Expiration Date' +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : EMPTY_BANNER_EXPIRATION_DATE)
                            }
                            nextMonthAriaLabel='Next month'
                            placeholder='YYYY/MM/DD'
                            previousMonthAriaLabel='Previous month'
                            todayAriaLabel='Today'
                        />
                    </FormField>
                </SpaceBetween>
            }
            actionName={mode === ModalModes.CREATE ? 'Submit' : 'Update'}
            action={() => {
                onBannerSubmitHandler()
            }}
            onDismiss={() => {
                setModalVisible(false)
                setSelectedBanner([])
            }}
            disabled={!validateBannerPayload(generateBannerPayload())}
        />
    )
}

export default CreateEditBannerModal
