// Promise polyfill
import 'core-js/features/promise'

import jwtDecode, { JwtPayload } from 'jwt-decode'
import { render } from 'react-dom'
import App from './components/App'
import '@amzn/awsui-global-styles/polaris.css'
import { getMidwayJwtToken } from './auth/MidwayJwtToken'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import { AppContext, UserProps } from './context'
import { getPermissions, ROLES } from './role'
import { SELECT_BUSINESS_ENTITY } from './components/Constant'
import CacheBuster from 'react-cache-buster'
import { ThemeProvider } from '@amzn/storm-ui'

const appVersion = require('../package.json').version
const cathodeVersions = require('@amzn/cathode-versions-javascript')

const BASE_URL = {
    prod: 'https://prod.api.falcon.birds.amazon.dev',
    gamma: 'https://gamma.api.falcon.birds.amazon.dev',
    beta: 'https://beta.api.falcon.birds.amazon.dev',
    local: 'http://localhost:8080',
}

;(async () => {
    let userAlias: string | undefined = ''
    if (process.env.NODE_ENV === 'production') {
        const token = await getMidwayJwtToken()
        const decoded = jwtDecode<JwtPayload>(token)
        userAlias = decoded.sub
    } else {
        // Web application running on local cannot get midway token.
        // In local running application, set user alias manually for testing
        userAlias = 'younghb'
    }

    const appSettings = (await axios('/settings.json')).data
    const stage = appSettings['stage']
    const apiUrl = BASE_URL[stage]

    const apiClient = axios.create({
        baseURL: apiUrl,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        responseType: 'json',
        timeout: 29_000,
    })

    // Add Authorization header to all axios calls
    apiClient.interceptors.request.use(async (config) => {
        if (process.env.NODE_ENV === 'production') {
            const jwtToken = await getMidwayJwtToken()
            config.headers.Authorization = `Bearer ${jwtToken}`
        }
        return config
    })

    apiClient.interceptors.response.use(
        function (response) {
            if (response.status === StatusCodes.UNAUTHORIZED) {
                console.error('You are not authorized to perform the operation')
            }
            return response
        },
        function (error) {
            return Promise.reject(error)
        },
    )

    const getUserRole = async (userBusinessEntity, userProps) => {
        const user = (await apiClient.get('/user/business-entity')).data
        const roles: ROLES[] = [ROLES.MANAGER]
        if (!user) {
            return roles
        }
        userBusinessEntity.id = user.business_entity_id
        userBusinessEntity.name = user.business_entity_name
        userBusinessEntity.slack_channels = user.slack_channels
        userBusinessEntity.slack_message_templates = user.slack_message_templates
        userBusinessEntity.configurations = user.configurations
        const userPermissions = await Promise.all([
            apiClient.get(`/user/is-admin?access_control_team_id=${user.admin_team_id}`),
            apiClient.get(`/user-roles/${userAlias}`).catch((err) => console.error(err)),
        ])
        // check if user has admin role by checking admin access control permission team membership
        // if user has no roles registered, request will return 500 err
        // catch it and keep roles list empty
        const isAdmin = userPermissions[0].data['isAdmin']
        const userRoleData = userPermissions[1]?.data || {}
        // Catch case where user selected business entity does not have roles in database
        if (!(user.business_entity_id in userRoleData)) {
            return roles
        }
        // check if user has STL role
        const userRoleObject = userRoleData[user.business_entity_id]
        const isSTL = userRoleObject['ROLES'].find((role) => role.id === ROLES.STL)
        const userPrograms = userRoleObject['USER_PROGRAMS']
        userProps.userPrograms = userPrograms
        if (isAdmin) {
            roles.push(ROLES.ADMIN)
        }
        if (isSTL) {
            roles.push(ROLES.STL)
        }
        // TODO:
        // This may change based on the role/permission definition
        return roles
    }

    const userProps: UserProps = {
        userAlias,
        role: [ROLES.VIEWER],
        userPrograms: [],
        permissions: getPermissions[ROLES.VIEWER],
    }
    const userBusinessEntity = {
        id: '',
        name: SELECT_BUSINESS_ENTITY,
        slack_channels: [{ slack_channel_id: '', slack_channel_name: '' }],
        slack_message_templates: [],
        configurations: { project: { non_admin_creation: false } },
    }
    const isProduction = process.env.NODE_ENV === 'production'

    const getUserPermissions = (userRole) => {
        const permissions: number[] = []
        userRole.forEach((role) => {
            permissions.concat(getPermissions[role])
        })
        return permissions
    }

    const userRole = await getUserRole(userBusinessEntity, userProps)
    userProps.role = userRole
    userProps.permissions = getUserPermissions(userRole)

    //User analytics integration(Spectrometer)
    const cathodeConfig = {
        siteName: 'Falcon',
        appName: 'FalconFrontendWebsite',
    }

    if (userAlias) {
        // Using logged in user as an unique id as cathode-customerId
        document.dispatchEvent(
            new CustomEvent('cathode-customerId', {
                detail: {
                    customerId: userAlias,
                },
            }),
        )
    }

    const scriptTags = cathodeVersions.getSpectrometerScriptTags(cathodeConfig, stage === 'prod')

    const rawBoomerangScript = scriptTags.boomerangLoaderScript

    const boomerangScript = rawBoomerangScript.substring(8, rawBoomerangScript.length - 9)

    const rawListenerScript = scriptTags.listenerScripts

    const listenerScript = rawListenerScript.substring(8, rawListenerScript.length - 9)

    const rawCathodeScript = scriptTags.cathodeScript

    const cathodeScript = rawCathodeScript.substring(8, rawCathodeScript.length - 9)

    render(
        <CacheBuster currentVersion={appVersion} isEnabled={isProduction} isVerboseMode={false}>
            <AppContext.Provider value={{ apiClient, userAlias, userProps }}>
                <ThemeProvider>
                    <App userBusinessEntity={userBusinessEntity} />
                </ThemeProvider>
            </AppContext.Provider>
        </CacheBuster>,

        document.querySelector('#app'),
    )

    window.eval(boomerangScript)
    window.eval(listenerScript)
    window.eval(cathodeScript)
})()
