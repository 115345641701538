import { Link, SelectProps, Popover, SpaceBetween, Icon, Box } from '@amzn/awsui-components-react'
import {
    COMPONENT_TYPES,
    ENTITLEMENT_CURRENCY,
    POPOVER_CONTENT_TYPES,
    PROGRAM_ATTRIBUTE_TYPES,
} from '../../Constant'
import DatePopover from '../reusable/DatePopover'
import { arrayGroupBy, convertBoolToBoolString, convertToLocalTime } from '../reusable/Utils'
import { generateProgramYearSelections } from './ProgramConstants'
import { getDateFormat, styleHeaderConditionally, styleHeaderWithNoWrap } from '../../common/Util'
import React from 'react'
import StatusIndicatorTemplate from '../reusable/StatusIndicatorTemplate'
import { YEAR_OPTIONS } from '../../../const/options'
import StatusInfoPopover from '../reusable/StatusInfoPopover'
import { getActiveForYearInfoMessage, getActiveInfoMessage } from '../reusable/TextUtil'
import GenericSummaryTable from '../reusable/GenericSummaryTable'

export const PROGRAM_SELECTION_IDS = ['program_theme', 'eoy_target_rl', 'primary_building_type']

interface ProgramAttribute {
    id: string
    headerName?: string
    headerDisplay?: any
    description?: string
    componentType?: COMPONENT_TYPES
    attributeType: PROGRAM_ATTRIBUTE_TYPES
    selections?: SelectProps.Option[]
    tableVisible: boolean
    summaryVisible: boolean
    required: boolean
    adminRequired: boolean // we'll have STL roles later, so keep this. but right now admin required = required
    inPayload: boolean
    checkDuplicate?: boolean
    defaultValue?: any
    disabled?: boolean
    tokenGroupInputId?: string
    tokenGroupLimit?: number
    saveTokensAsList?: boolean
    rowDefinition?: number
    trueProgramOnly?: boolean
    showConditionally?: boolean // define conditional attr first
    conditionalOnAttr?: string
    showOnValue?: any
    convertValueType?: boolean
    convertValueTypeFuncPayload?: any
    allProgramsViewOnly?: boolean
    exportName?: string
    inExportSummary?: boolean
    infoContent?: any
    popoverContentType?: any
    popoverTableColumns?: any
    popoverTableVisibleColumns?: any
}

export const programAttributes: ProgramAttribute[] = [
    {
        id: 'program_id',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        inPayload: true,
    },
    {
        id: 'local_program_id',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        inPayload: true,
    },
    {
        id: 'program_name',
        headerName: 'Program Name',
        description: 'Name of the program',
        componentType: COMPONENT_TYPES.INPUT_STRING,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: false,
        summaryVisible: true,
        required: true,
        adminRequired: false,
        checkDuplicate: true,
        inPayload: true,
        rowDefinition: 1,
        exportName: 'Program Name',
        inExportSummary: true,
    },
    {
        id: 'business_entity_name',
        headerName: 'Business Entities',
        componentType: COMPONENT_TYPES.INPUT_STRING,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: true,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: false,
        allProgramsViewOnly: true,
    },
    {
        id: 'stl_name',
        headerName: 'STL Name',
        description: 'Single Threaded Leader name',
        componentType: COMPONENT_TYPES.INPUT_STRING,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: true,
        summaryVisible: true,
        required: true,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 1,
        exportName: 'STL Name',
        inExportSummary: true,
    },
    {
        id: 'program_project_year',
        headerName: 'Program Year',
        description: 'Year of the program',
        componentType: COMPONENT_TYPES.SELECT,
        selections: generateProgramYearSelections(),
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: true,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 2,
    },
    {
        id: 'stl_alias',
        headerName: 'STL Alias',
        description: 'Single Threaded Leader alias',
        componentType: COMPONENT_TYPES.TOKEN_GROUP,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: true,
        summaryVisible: true,
        required: true,
        adminRequired: false,
        defaultValue: [],
        checkDuplicate: false,
        inPayload: true,
        tokenGroupInputId: 'stl_alias_input',
        tokenGroupLimit: 1,
        saveTokensAsList: false,
        rowDefinition: 2,
        exportName: 'STL Alias',
        inExportSummary: true,
    },
    {
        id: 'stl_alias_input',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        defaultValue: '',
        checkDuplicate: false,
        inPayload: false,
    },
    {
        id: 'is_true_program',
        headerName: 'Is Program',
        headerDisplay: (
            <>
                Is <br></br> Program
            </>
        ),
        description: 'Select if this is a true program',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: false,
        summaryVisible: false,
        required: true,
        adminRequired: false,
        checkDuplicate: false,
        defaultValue: true,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'number_of_projects',
        headerName: '# of Projects',
        headerDisplay: (
            <>
                # of <br></br> Projects
            </>
        ),
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: true,
        summaryVisible: true,
        required: false,
        inPayload: false,
        adminRequired: false,
        exportName: '# of Projects',
        inExportSummary: true,
    },
    {
        id: 'op_years',
        headerName: 'OP Years',
        headerDisplay: 'OP Years',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        componentType: COMPONENT_TYPES.POPOVER,
        tableVisible: true,
        summaryVisible: true,
        required: false,
        inPayload: false,
        adminRequired: false,
        exportName: 'OP Years',
        inExportSummary: true,
        allProgramsViewOnly: true,
        popoverContentType: POPOVER_CONTENT_TYPES.TABLE,
        popoverTableColumns: [
            {
                id: 'year',
                header: 'Year',
                cell: (item) => item.year,
                sortingField: 'year',
            },
            {
                id: 'plan',
                header: 'Plan',
                cell: (item) => item.plan,
                sortingField: 'plan',
            },
            {
                id: 'ar_status',
                header: 'AR Status',
                cell: (item) => item.ar_status,
                sortingField: 'ar_status',
            },
            {
                id: 'grd_status',
                header: 'GRD Status',
                cell: (item) => item.grd_status,
                sortingField: 'grd_status',
            },
        ],
        popoverTableVisibleColumns: [
            { id: 'year', visible: true },
            { id: 'plan', visible: true },
            { id: 'ar_status', visible: true },
            { id: 'grd_status', visible: true },
        ],
    },
    {
        id: 'is_overhead_applied',
        headerName: 'Subject to OH',
        headerDisplay: (
            <>
                Subject <br></br> to OH{' '}
            </>
        ),
        description: 'Select if overhead should be applied to headcount/effort estimates',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        defaultValue: true,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'registered_users',
        headerName: 'Registered Users',
        description: 'Registered users for the program',
        componentType: COMPONENT_TYPES.TOKEN_GROUP,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: true,
        defaultValue: [],
        summaryVisible: true,
        required: false,
        adminRequired: false,
        checkDuplicate: true,
        inPayload: true,
        tokenGroupInputId: 'registered_users_input',
        saveTokensAsList: true,
        rowDefinition: 2,
        exportName: 'Registered Users',
        inExportSummary: true,
    },
    {
        id: 'registered_users_input',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.CORE,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        defaultValue: '',
        checkDuplicate: false,
        inPayload: false,
    },
    {
        id: 'description',
        headerName: 'Description',
        description: 'Description of the program',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: true,
        summaryVisible: true,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 1,
        trueProgramOnly: true,
        exportName: 'Description',
    },
    {
        id: 'owned_org',
        headerName: 'Owned Org',
        description: 'Organization who is responsible for this program',
        componentType: COMPONENT_TYPES.INPUT_STRING,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: true,
        summaryVisible: true,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 4,
        trueProgramOnly: true,
        exportName: 'Owned Org',
        inExportSummary: true,
    },
    {
        id: 'program_theme',
        headerName: 'Program Theme',
        description: 'Strategic theme for this program',
        componentType: COMPONENT_TYPES.SELECT,
        selections: [],
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: true,
        summaryVisible: true,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 3,
        trueProgramOnly: true,
        exportName: 'Program Theme',
        inExportSummary: true,
    },
    {
        id: 'justification',
        headerName: 'Justification',
        description:
            'Why is this program/project important to the customer? What is it trying to achieve?',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 1,
        trueProgramOnly: true,
        exportName: 'Justification',
    },
    {
        id: 'year_end_goal',
        headerName: 'Year End Goal',
        description: 'What will the program deliver by year end?',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 2,
        trueProgramOnly: true,
        exportName: 'Year End Goal',
    },
    {
        id: 'program_note',
        headerName: 'Program Note',
        description: 'Notes/Comments regarding the program',
        componentType: COMPONENT_TYPES.TEXTAREA,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: true,
        summaryVisible: true,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 2,
        trueProgramOnly: true,
        exportName: 'Program Note',
    },
    {
        id: 'eoy_target_rl',
        headerName: 'EOY Target RL',
        description: 'End of Year Target Program/Readiness Level',
        componentType: COMPONENT_TYPES.SELECT,
        selections: [],
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 3,
        trueProgramOnly: true,
        exportName: 'EOY Target RL',
    },
    {
        id: 'primary_building_type',
        headerName: 'Primary Building Type',
        componentType: COMPONENT_TYPES.SELECT,
        selections: [],
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 3,
        trueProgramOnly: true,
        exportName: 'Primary Building Type',
    },
    {
        id: 'ga_target_date',
        headerName: 'GA Target Date',
        componentType: COMPONENT_TYPES.INPUT_STRING,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 4,
        trueProgramOnly: true,
        exportName: 'GA Target Date',
    },
    {
        id: 'three_year_entitlement',
        headerName: '3 Year Entitlement',
        description: ENTITLEMENT_CURRENCY.MILLION,
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 4,
        trueProgramOnly: true,
        exportName: '3 Year Entitlement',
    },
    {
        id: 'five_year_entitlement',
        headerName: '5 Year Entitlement',
        description: ENTITLEMENT_CURRENCY.MILLION,
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.GLOBAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 4,
        trueProgramOnly: true,
        exportName: '5 Year Entitlement',
    },
    {
        id: 'priority',
        headerName: 'Priority',
        description: 'Used to organize the program sequence for reporting',
        componentType: COMPONENT_TYPES.INPUT_INT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 1,
        exportName: 'Priority',
    },
    {
        id: 'display_seq',
        headerName: 'Display Seq',
        description: 'Secondary sort value',
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'is_locked',
        headerName: 'Is Locked',
        description: 'Lock the program to disable estimates against it',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'is_carryover',
        headerName: 'Is Carryover',
        description: "Was this a program in last year's OP2?",
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 1,
    },
    {
        id: 'is_active',
        headerName: 'Is Active',
        headerDisplay: (
            <>
                Is <br></br> Active
            </>
        ),
        description: '',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        checkDuplicate: false,
        inPayload: true,
        defaultValue: true,
        adminRequired: false,
        convertValueType: true,
        convertValueTypeFuncPayload: (val) => convertBoolToBoolString(val),
        rowDefinition: 4,
        infoContent: <StatusInfoPopover popoverContent={getActiveInfoMessage('program')} />,
    },
    {
        id: 'is_active_for_year',
        headerName: 'Is Active For Year',
        headerDisplay: (
            <>
                Is <br></br> Active
            </>
        ),
        description: '',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        required: false,
        checkDuplicate: false,
        inPayload: false,
        defaultValue: true,
        adminRequired: true,
        convertValueType: true,
        disabled: true,
        convertValueTypeFuncPayload: (val) => convertBoolToBoolString(val),
        rowDefinition: 4,
        infoContent: <StatusInfoPopover popoverContent={getActiveForYearInfoMessage('program')} />,
    },
    {
        id: 'active_status_effective_date',
        headerName: 'Effective Date',
        description: '',
        componentType: COMPONENT_TYPES.DATE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        checkDuplicate: false,
        inPayload: true,
        defaultValue: getDateFormat(new Date()),
        adminRequired: false,
    },
    {
        id: 'op1_estimate_ct',
        headerName: 'OP1 Estimated C&T',
        description: 'C&T Headcount from OP1',
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 2,
        exportName: 'OP1 Estimated C&T',
    },
    {
        id: 'op1_estimate_ff',
        headerName: 'OP1 Estimated FF',
        description: 'FF Headcount from OP1',
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 2,
        exportName: 'OP1 Estimated FF',
    },
    {
        id: 'op1_estimate_total',
        headerName: 'OP1 Estimate Total',
        description: 'Total Headcount from OP1',
        componentType: COMPONENT_TYPES.INPUT_FLOAT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: false,
        disabled: true,
        rowDefinition: 2,
        exportName: 'OP1 Estimate Total',
    },
    {
        id: 'is_op_goal',
        headerName: 'Is OP Goal',
        componentType: COMPONENT_TYPES.TOGGLE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: true,
        summaryVisible: true,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
        rowDefinition: 3,
        exportName: 'Is OP Goal',
        inExportSummary: true,
    },
    {
        id: 'op_year',
        headerName: 'OP Year',
        componentType: COMPONENT_TYPES.SELECT,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        selections: YEAR_OPTIONS,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        checkDuplicate: false,
        inPayload: true,
    },
    {
        id: 'created_by',
        headerName: 'Created By',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        inPayload: false,
        exportName: 'Created By',
    },
    {
        id: 'created_at',
        headerName: 'Created At',
        componentType: COMPONENT_TYPES.DATE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        adminRequired: false,
        inPayload: false,
    },
    {
        id: 'updated_by',
        headerName: 'Updated By',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        inPayload: false,
        adminRequired: false,
        exportName: 'Updated By',
    },
    {
        id: 'updated_at',
        headerName: 'Updated At',
        componentType: COMPONENT_TYPES.DATE,
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        tableVisible: false,
        summaryVisible: false,
        required: false,
        inPayload: false,
        adminRequired: false,
    },
]
export const getAttrDef = (id) => {
    return programAttributes.find((attr) => attr.id === id)
}

export const getProgramCoreAttributes = () => {
    return programAttributes.flatMap((attr) =>
        attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.CORE ? [attr] : [],
    )
}

export const getProgramGlobalAttributes = () => {
    return programAttributes.flatMap((attr) =>
        attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.GLOBAL ? [attr] : [],
    )
}

export const getProgramLocalAttributes = () => {
    return programAttributes.flatMap((attr) =>
        attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.LOCAL ? [attr] : [],
    )
}

export const getAttributesByRow = (attrType) => {
    switch (attrType) {
        case PROGRAM_ATTRIBUTE_TYPES.CORE:
            return arrayGroupBy(
                getProgramCoreAttributes().filter((attr) => attr.rowDefinition !== undefined),
                'rowDefinition',
            )
        case PROGRAM_ATTRIBUTE_TYPES.LOCAL:
            return arrayGroupBy(
                getProgramLocalAttributes().filter((attr) => attr.rowDefinition !== undefined),
                'rowDefinition',
            )
        case PROGRAM_ATTRIBUTE_TYPES.GLOBAL:
            return arrayGroupBy(
                getProgramGlobalAttributes().filter((attr) => attr.rowDefinition !== undefined),
                'rowDefinition',
            )
        default:
            return {}
    }
}

export const getProgramTableVisibleColumns = () => {
    const definitions = programAttributes.flatMap((attr) => (attr.tableVisible ? [attr.id] : []))
    definitions.unshift('prog_name_display')

    return definitions
}

export const getProgramSummaryVisibleColumns = () => {
    const definitions = programAttributes.flatMap((attr) => (attr.summaryVisible ? [attr.id] : []))
    definitions.unshift('prog_name_display')

    return definitions
}

export const preprocessValueDisplay = (attrDef, state) => {
    const defaultVal = attrDef.defaultValue
    let displayVal = state[attrDef?.id] ?? defaultVal
    if (attrDef && attrDef?.showConditionally && attrDef?.showOnValue !== undefined) {
        displayVal = state[attrDef.conditionalOnAttr] === attrDef.showOnValue ? displayVal : ''
    }
    return displayVal
}

export const preprocessValuePayload = (attrDef, value) => {
    if (attrDef && attrDef?.convertValueType) {
        return attrDef.convertValueTypeFuncPayload(value)
    }
    return value
}

const renderCell = (item, attr: ProgramAttribute) => {
    const attrVal = preprocessValueDisplay(attr, item)
    switch (attr.componentType) {
        case COMPONENT_TYPES.TOGGLE:
            return <StatusIndicatorTemplate value={attrVal} />
        case COMPONENT_TYPES.SELECT:
            const value = attr.selections
                ? attr.selections.find((option) => option.value === attrVal)
                : undefined
            return value ? value.label : ''
        case COMPONENT_TYPES.DATE:
            return <DatePopover date={convertToLocalTime(attrVal)} />
        case COMPONENT_TYPES.LINK:
            return (
                <Link external href={attrVal || '#'}>
                    {attrVal}
                </Link>
            )
        case COMPONENT_TYPES.TOKEN_GROUP:
            return item[attr.id].join(', ')
        case COMPONENT_TYPES.POPOVER:
            return item[attr.id].length ? (
                <Popover
                    size='large'
                    position='right'
                    fixedWidth
                    content={renderPopoverContent(item[attr.id], attr.popoverContentType, attr)}
                >
                    <SpaceBetween size='xxs' direction={'horizontal'}>
                        <Icon name='status-info' />
                        <Box color={'text-body-secondary'}> View </Box>
                    </SpaceBetween>
                </Popover>
            ) : (
                <></>
            )
        default:
            return attrVal
    }
}

const renderPopoverContent = (content, contentType, attr: ProgramAttribute) => {
    switch (contentType) {
        case POPOVER_CONTENT_TYPES.TABLE:
            return (
                <Box margin={{ left: 's', right: 's' }}>
                    <GenericSummaryTable
                        itemsToShow={content}
                        actions={[]}
                        columns={attr.popoverTableColumns}
                        visibleColumns={attr.popoverTableVisibleColumns}
                        defaultNameField={''}
                        nameField={''}
                        isLoading={!content.length}
                        objectType={'Years'}
                        includeHeader={false}
                        wrapLines
                        variant={'borderless'}
                        includePagination={false}
                    ></GenericSummaryTable>
                </Box>
            )
        default:
            return content
    }
}

const programNameRender = (history) => {
    return {
        id: 'prog_name_display',
        header: styleHeaderWithNoWrap('Program'),
        cell: (item) => (
            <Link
                onFollow={(event) => {
                    event.preventDefault()
                    const programId = item.program_id
                    const localProgramId = item.local_program_id
                    history.push(`/program/${programId}/${localProgramId}/projects`)
                    localStorage.setItem('selectedProgramBusinessEntity', item.business_entity_id)
                }}
            >
                {item.program_name}
            </Link>
        ),
        sortingField: 'program_name',
    }
}

export const getExportSummaryProgramColumnDefinition = (programAttributes) => {
    return programAttributes.flatMap((attr) =>
        attr.inExportSummary
            ? [
                  {
                      id: attr.id,
                      header: styleHeaderConditionally(attr),
                      cell: (item) => renderCell(item, attr),
                      sortingField: attr.id,
                      exportName: attr.exportName,
                  },
              ]
            : [],
    )
}

export const getProgramColumnDefinitions = (history, programAttributes) => {
    const definitions = programAttributes.flatMap((attr) =>
        !attr.allProgramsViewOnly
            ? [
                  {
                      id: attr.id,
                      header: styleHeaderConditionally(attr),
                      cell: (item) => renderCell(item, attr),
                      sortingField: attr.id,
                      exportName: attr.exportName,
                  },
              ]
            : [],
    )
    definitions.unshift(programNameRender(history))

    return definitions
}

export const programNameRenderAllPrograms = (onProgramNameClick) => {
    return {
        id: 'prog_name_display',
        header: styleHeaderWithNoWrap('Program'),
        cell: (item) => (
            <Link
                onFollow={(event) => {
                    event.preventDefault()
                    onProgramNameClick(item)
                }}
            >
                {item.program_name}
            </Link>
        ),
        sortingField: 'program_name',
    }
}

export const getCoreProgramColumnDefinitions = (onProgramNameClick, programAttributes) => {
    const definitions = programAttributes.flatMap((attr) =>
        attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.CORE
            ? [
                  {
                      id: attr.id,
                      header: styleHeaderConditionally(attr),
                      cell: (item) => renderCell(item, attr),
                      sortingField: attr.id,
                      exportName: attr.exportName,
                  },
              ]
            : [],
    )
    definitions.push(
        {
            id: 'updated_by',
            header: 'Updated By',
            cell: (item) => item['updated_by'],
            sortingField: 'updated_by',
            exportName: 'Updated By',
        },
        {
            id: 'updated_at',
            header: 'Updated At',
            cell: (item) => <DatePopover date={convertToLocalTime(item.updated_at)} />,
            sortingField: 'updated_at',
        },
    )
    definitions.unshift(programNameRenderAllPrograms(onProgramNameClick))

    return definitions
}

export const getCoreProgramTableVisibleColumns = () => {
    const definitions = programAttributes.flatMap((attr) =>
        attr.tableVisible && attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.CORE ? [attr.id] : [],
    )
    definitions.unshift('prog_name_display')

    return definitions
}

export const getCoreProgramSummaryVisibleColumns = () => {
    const definitions = programAttributes.flatMap((attr) =>
        attr.summaryVisible && attr.attributeType === PROGRAM_ATTRIBUTE_TYPES.CORE ? [attr.id] : [],
    )
    definitions.unshift('prog_name_display')

    return definitions
}

export const getFormattedAttributes = (selections, attributes) => {
    const result = [...attributes]
    const formattedSelections: Record<string, Array<{ label: string; value: string }>> = {}
    selections.forEach(({ key, display_name, value }) => {
        if (!(key in formattedSelections)) {
            formattedSelections[key] = []
        }
        formattedSelections[key].push({
            label: display_name,
            value: value,
        })
    })

    Object.keys(formattedSelections).forEach(function (key) {
        const attrIndex = result.findIndex((attr) => attr.id === key)
        if (attrIndex) {
            result[attrIndex].selections = formattedSelections[key]
        }
    })

    return result
}
