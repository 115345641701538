import React, { useEffect, useState } from 'react'
import {
    Alert,
    Box,
    Container,
    ContentLayout,
    Flashbar,
    SpaceBetween,
    Tabs,
} from '@amzn/awsui-components-react/polaris'
import OrgGroupList from './OrgGroupList'
import OrgGroupTeamList from './OrgGroupTeamList'
import BulkCreateTeam from './BulkCreateTeam'
import EditTeam from './EditTeam'
import EditGroup from './EditGroup'
import { filterActiveGroupsTeams, getFalconGroups } from '../../common/Util'
import { AlertTypes } from '../../Constant'
import { CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import { useAppContext } from '../../../context'
import HeaderTemplate from '../reusable/HeaderTemplate'
import BulkCreateGroupTeamWizard from './BulkCreateGroupTeamWizard'
import { useLocation } from 'react-router-dom'
import { ALERT_TEMPLATE, LIMITED_ACCESS_MESSAGE, NO_PERMISSION_MESSAGE } from '../reusable/TextUtil'
import DeleteModal from '../reusable/DeleteModal'
import useStore from '../../Store'
import MoveTeams from './MoveTeams'
import MoveGroups from './MoveGroups'

const OrgDetail = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const location: any = useLocation()
    const path = location.pathname.split('/')
    let orgId: string
    if (path.length >= 3 && path[1] === 'org') {
        orgId = path[2]
    } else {
        orgId = ''
    }

    const canAdmin = useStore((state) => state.canAdmin)

    const [groupModalVisible, setGroupModalVisible] = useState(false)
    const [groupEditModalVisible, setGroupEditModalVisible] = useState(false)
    const [deleteGroupModalVisible, setDeleteGroupModalVisible] = useState(false)
    const [groups, setGroups] = useState<any[]>([])
    const [visibleGroups, setVisibleGroups] = useState<any[]>([])
    const [visibleTeams, setVisibleTeams] = useState<any[]>([])
    const [showFalconGroups, setShowFalconGroups] = useState(true)
    const [showActiveGroups, setShowActiveGroups] = useState(true)
    const [showActiveTeams, setShowActiveTeams] = useState(true)
    const [isGroupLoading, setIsGroupLoading] = useState(false)
    const [teamModalVisible, setTeamModalVisible] = useState(false)
    const [teamEditModalVisible, setTeamEditModalVisible] = useState(false)
    const [deleteTeamModalVisible, setDeleteTeamModalVisible] = useState(false)
    const [moveTeamModalVisible, setMoveTeamModalVisible] = useState(false)
    const [moveGroupModalVisible, setMoveGroupModalVisible] = useState(false)
    const [teams, setTeams] = useState([])
    const [selectedTeams, setSelectedTeams] = useState<any>([])
    const [isTeamLoading, setIsTeamLoading] = useState(false)
    const [alertItems, setAlertItems] = useState<any>([])
    const [selectedGroups, setSelectedGroups] = useState<any>([])
    const [orgName, setOrgName] = useState('')
    const [hasAuth, setHasAuth] = useState(true)
    const [activeTabId, setActiveTabId] = useState(`${orgName}#groups`)

    useEffect(() => {
        if (!canAdmin) {
            return
        }
        let itemOptions = !showFalconGroups ? [...groups] : getFalconGroups([...groups])
        itemOptions = showActiveGroups ? filterActiveGroupsTeams(itemOptions) : itemOptions
        setVisibleGroups(itemOptions)
    }, [groups, showFalconGroups, showActiveGroups])

    useEffect(() => {
        if (!canAdmin) {
            return
        }
        const itemOptions = showActiveTeams ? filterActiveGroupsTeams(teams) : teams
        setVisibleTeams(itemOptions)
    }, [teams, showActiveTeams])

    useEffect(() => {
        // ensure we de-select items that should have been filtered out
        if (!selectedGroups || !selectedGroups.length) {
            return
        }
        setSelectedGroups(
            selectedGroups.filter(
                (gp) =>
                    !((showFalconGroups && !gp.is_falcon) || (showActiveGroups && !gp.is_active)),
            ),
        )
    }, [showFalconGroups, showActiveGroups])

    useEffect(() => {
        // ensure we de-select items that should have been filtered out
        if (!selectedTeams || !selectedTeams.length) {
            return
        }
        setSelectedTeams(selectedTeams.filter((team) => !(showActiveTeams && !team.is_active)))
    }, [showActiveTeams])

    const handleGroupModalClose = () => {
        localStorage.clear()
        setActiveTabId(`${orgName}#groups`)
        setGroupModalVisible(false)
    }

    const handleGroupEdit = () => {
        getAllTeamsUnderOrg(orgId)
        setGroupEditModalVisible(true)
    }

    const handleDeleteGroups = () => {
        setDeleteGroupModalVisible(true)
    }

    const handleGroupEditModalClose = () => {
        localStorage.clear()
        setActiveTabId(`${orgName}#groups`)
        setGroupEditModalVisible(false)
    }

    const handleTeamModalClose = () => {
        setActiveTabId(`${orgName}#teams`)
        setTeamModalVisible(false)
    }

    const handleTeamEdit = () => {
        setTeamEditModalVisible(true)
    }

    const handleDeleteTeams = () => {
        setDeleteTeamModalVisible(true)
    }

    const handleMoveTeams = () => {
        setMoveTeamModalVisible(true)
    }

    const handleMoveTeamsModalClose = () => {
        setActiveTabId(`${orgName}#teams`)
        setMoveTeamModalVisible(false)
    }

    const handleMoveGroups = () => {
        setMoveGroupModalVisible(true)
    }

    const handleMoveGroupsModalClose = () => {
        localStorage.clear()
        setActiveTabId(`${orgName}#groups`)
        setMoveGroupModalVisible(false)
    }

    const handleTeamEditModalClose = () => {
        setActiveTabId(`${orgName}#teams`)
        setTeamEditModalVisible(false)
    }

    const getAllGroupsUnderOrg = (orgId) => {
        apiClient
            .get(`/orgs/${orgId}/groups`)
            .then((response) => {
                // put all Falcon groups first; within block sort by alpha order
                const allGps = response.data.sort((gp1, gp2) =>
                    gp1?.is_falcon === gp2?.is_falcon
                        ? gp1.group_name < gp2.group_name
                            ? -1
                            : 1
                        : gp1?.is_falcon
                          ? -1
                          : 1,
                )
                setGroups(allGps)
                let visibleGps = showFalconGroups ? getFalconGroups([...allGps]) : [...allGps]
                visibleGps = showActiveGroups ? filterActiveGroupsTeams(visibleGps) : visibleGps
                setVisibleGroups(visibleGps)
                setIsGroupLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsGroupLoading(false)
            })
    }

    const getAllTeamsUnderOrg = (orgId) => {
        apiClient
            .get(`/orgs/${orgId}/teams`)
            .then((response) => {
                const allTeams = response.data
                setTeams(allTeams)
                setVisibleTeams(showActiveTeams ? filterActiveGroupsTeams(allTeams) : allTeams)
                setIsTeamLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsTeamLoading(false)
            })
    }

    const getOrgInfo = (orgId) => {
        apiClient
            .get(`/org/${orgId}`)
            .then((response) => {
                const data = response.data
                apiClient
                    .get(`/user/business-entity/${data.business_entity_id}/has-auth`)
                    .then((response) => {
                        const res = response.data
                        setHasAuth(res)
                        if (res) {
                            setOrgName(data['org_name'])
                            const isTeamsTabOpen =
                                localStorage.getItem('orgTeamTabOpen') ||
                                activeTabId.includes('teams')
                            setActiveTabId(
                                `${data['org_name']}#${isTeamsTabOpen ? 'teams' : 'groups'}`,
                            )
                            getAllGroupsUnderOrg(orgId)
                            getAllTeamsUnderOrg(orgId)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        setIsGroupLoading(false)
                        setIsTeamLoading(false)
                    })
            })
            .catch((error) => {
                console.error(error)
                setIsGroupLoading(false)
                setIsTeamLoading(false)
            })
    }

    const deleteGroups = () => {
        const group_ids = selectedGroups.map((group) => group['group_id']).join(',')
        const url = `/orgs/${orgId}/groups?group_ids=${group_ids}`
        setDeleteGroupModalVisible(false)
        setIsGroupLoading(true)
        apiClient
            .delete(url)
            .then(() => {
                getAllGroupsUnderOrg(orgId)
                setAlertItems([
                    {
                        type: AlertTypes.SUCCESS,
                        content: `Successfully deleted group(s) ${selectedGroups.map(
                            (group) => group.group_name,
                        )}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setAlertItems([]),
                    },
                ])
                setSelectedGroups([])
            })
            .catch((error) => {
                setAlertItems([
                    {
                        type: AlertTypes.ERROR,
                        content: `Failed to delete group(s) ${selectedGroups.map(
                            (group) => group.group_name,
                        )}: ${error.response.data}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setAlertItems([]),
                    },
                ])
                setSelectedGroups([])
                setIsGroupLoading(false)
                console.error(error)
            })
    }

    const deleteTeams = () => {
        const team_ids = selectedTeams.map((team) => team['team_id']).join(',')
        const url = `/orgs/${orgId}/teams?team_ids=${team_ids}`
        setDeleteTeamModalVisible(false)
        setIsTeamLoading(true)
        apiClient
            .delete(url)
            .then(() => {
                getAllTeamsUnderOrg(orgId)
                setAlertItems([
                    {
                        type: AlertTypes.SUCCESS,
                        content: `Successfully deleted team(s) ${selectedTeams.map(
                            (team) => team.team_name,
                        )}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setAlertItems([]),
                    },
                ])
                setSelectedTeams([])
            })
            .catch((error) => {
                setAlertItems([
                    {
                        type: AlertTypes.ERROR,
                        content: `Failed to delete team(s) ${selectedTeams.map(
                            (team) => team.team_name,
                        )}: ${error.response.data}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setAlertItems([]),
                    },
                ])
                setSelectedTeams([])
                setIsTeamLoading(false)
                console.error(error)
            })
    }

    useEffect(() => {
        setIsGroupLoading(true)
        setIsTeamLoading(true)
        getOrgInfo(orgId)
        setSelectedGroups([])
        setSelectedTeams([])
    }, [alertItems])

    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween direction='vertical' size='s'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                { text: 'Organizations', href: '/orgs' },
                                { text: `${orgName}`, href: '' },
                            ]}
                        />
                        {!hasAuth ? (
                            <ALERT_TEMPLATE
                                type={AlertTypes.WARNING}
                                header='Access Denied'
                                text={<NO_PERMISSION_MESSAGE />}
                            />
                        ) : canAdmin ? (
                            <></>
                        ) : (
                            <Alert header='Limited Access'>
                                <LIMITED_ACCESS_MESSAGE
                                    item='group/team'
                                    link={CREATE_TEAM_REQUEST}
                                />
                            </Alert>
                        )}
                        <Flashbar items={alertItems} />
                    </SpaceBetween>
                </Box>
            }
        >
            <DeleteModal
                title='Group(s)'
                visible={deleteGroupModalVisible}
                onDismiss={() => setDeleteGroupModalVisible(false)}
                onDelete={deleteGroups}
            />
            <DeleteModal
                title='Team(s)'
                visible={deleteTeamModalVisible}
                onDismiss={() => setDeleteTeamModalVisible(false)}
                onDelete={deleteTeams}
            />
            <Box margin={{ left: 's', right: 's' }}>
                <Container>
                    <Tabs
                        activeTabId={activeTabId}
                        onChange={({ detail }) => {
                            localStorage.clear()
                            setActiveTabId(detail.activeTabId)
                        }}
                        tabs={[
                            {
                                label: 'Groups',
                                id: `${orgName}#groups`,
                                content: (
                                    <Box>
                                        <OrgGroupList
                                            canAdmin={canAdmin}
                                            orgName={orgName}
                                            groups={visibleGroups}
                                            isLoading={isGroupLoading}
                                            onCreate={setGroupModalVisible}
                                            onEdit={handleGroupEdit}
                                            onDelete={handleDeleteGroups}
                                            onMove={handleMoveGroups}
                                            selectedGroups={selectedGroups}
                                            onSelectedGroupsChange={setSelectedGroups}
                                            showFalconGroups={showFalconGroups}
                                            setShowFalconGroups={setShowFalconGroups}
                                            showActiveGroups={showActiveGroups}
                                            setShowActiveGroups={setShowActiveGroups}
                                        />
                                        <BulkCreateGroupTeamWizard
                                            orgId={orgId}
                                            orgName={orgName}
                                            groups={groups}
                                            onGroupsChange={() => getAllGroupsUnderOrg(orgId)}
                                            teams={teams}
                                            onTeamsChange={() => getAllTeamsUnderOrg(orgId)}
                                            visible={groupModalVisible}
                                            onGroupLoadingChange={setIsGroupLoading}
                                            onGroupModalDismiss={handleGroupModalClose}
                                            onOrgHeaderAlertChange={setAlertItems}
                                        />
                                        <EditGroup
                                            orgId={orgId}
                                            orgName={orgName}
                                            groups={groups}
                                            selectedGroups={selectedGroups}
                                            onSelectedGroupsChange={setSelectedGroups}
                                            groupEditModalVisible={groupEditModalVisible}
                                            onGroupEditModalDismiss={handleGroupEditModalClose}
                                            onOrgHeaderAlertChange={setAlertItems}
                                            onGroupLoadingChange={setIsGroupLoading}
                                            refreshGroupsList={() => getAllGroupsUnderOrg(orgId)}
                                            refreshTeamsList={() => getAllTeamsUnderOrg(orgId)}
                                            activeTeams={
                                                teams?.filter((team: any) => team.is_active) || []
                                            }
                                        />
                                        <MoveGroups
                                            modalVisible={moveGroupModalVisible}
                                            closeModalHandler={handleMoveGroupsModalClose}
                                            selectedGroups={selectedGroups}
                                            setSelectedGroups={setSelectedGroups}
                                            setAlertItems={setAlertItems}
                                        />
                                    </Box>
                                ),
                            },
                            {
                                label: 'Teams',
                                id: `${orgName}#teams`,
                                content: (
                                    <Box>
                                        <OrgGroupTeamList
                                            canAdmin={canAdmin}
                                            orgName={orgName}
                                            teams={visibleTeams}
                                            selectedTeams={selectedTeams}
                                            onSelectedTeamsChange={setSelectedTeams}
                                            isLoading={isTeamLoading}
                                            onCreate={setTeamModalVisible}
                                            onEdit={handleTeamEdit}
                                            onDelete={handleDeleteTeams}
                                            onMove={handleMoveTeams}
                                            showActiveTeams={showActiveTeams}
                                            setShowActiveTeams={setShowActiveTeams}
                                        />
                                        <BulkCreateTeam
                                            orgId={orgId}
                                            orgName={orgName}
                                            groups={groups}
                                            teams={teams}
                                            visible={teamModalVisible}
                                            onTeamLoadingChange={setIsTeamLoading}
                                            onTeamCreateModalDismiss={handleTeamModalClose}
                                            onOrgHeaderAlertChange={setAlertItems}
                                            refreshList={() => getAllTeamsUnderOrg(orgId)}
                                        />
                                        <EditTeam
                                            orgId={orgId}
                                            orgName={orgName}
                                            groups={groups}
                                            teams={teams}
                                            selectedTeams={selectedTeams}
                                            onSelectedTeamsChange={setSelectedTeams}
                                            teamEditModalVisible={teamEditModalVisible}
                                            onTeamEditModalDismiss={handleTeamEditModalClose}
                                            onOrgHeaderAlertChange={setAlertItems}
                                            onTeamLoadingChange={setIsTeamLoading}
                                            refreshList={() => getAllTeamsUnderOrg(orgId)}
                                        />
                                        <MoveTeams
                                            modalVisible={moveTeamModalVisible}
                                            closeModalHandler={handleMoveTeamsModalClose}
                                            selectedTeams={selectedTeams}
                                            setSelectedTeams={setSelectedTeams}
                                            setAlertItems={setAlertItems}
                                        />
                                    </Box>
                                ),
                            },
                        ]}
                    />
                </Container>
            </Box>
        </ContentLayout>
    )
}

export default OrgDetail
