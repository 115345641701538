import { v4 as uuidv4 } from 'uuid'
import dateFormat from 'dateformat'
import { BADGE_COLOR, BADGE_NAME } from '../Constant'
import getUrls from 'get-urls'
import { Link } from '@amzn/awsui-components-react'
import React from 'react'

export const getDateFormat = (date: Date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
}

export const getDateByDayDifference = (dateString: string, dayDifference: number) => {
    const date = new Date(dateString)
    date.setUTCDate(date.getUTCDate() + dayDifference)
    return getDateFormat(date)
}

export const generateId = () => {
    return uuidv4()
}

export const convertToLocalTime = (utcDateTime) => {
    return utcDateTime ? dateFormat(new Date(utcDateTime), 'yyyy-mm-dd HH:MM:ss') : ''
}

export const isDuplicateByPropertyValue = (
    skipIdName: string,
    skipId: string,
    list,
    property: string,
    value,
) => {
    // skip check duplicate by skipIdName and skipId if that item is current editing
    return (
        list.findIndex((item) => {
            if (item[skipIdName] === skipId) {
                return false
            }
            return item[property] === value
        }) !== -1
    )
}

export const convertAlertContentWithLink = (content: string, link_content: string) => {
    return (
        <>
            {content.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')}{' '}
            <Link
                external
                color='inverted'
                externalIconAriaLabel='Opens in a new tab'
                href={Array.from(getUrls(content)).join(',')}
            >
                {link_content}
            </Link>
        </>
    )
}

export const isValidNumericString = (inputStr) => {
    const digitAndDecimals = new RegExp('\\d*\\.?\\d+')
    return !inputStr.replace(digitAndDecimals, '').length
}

export const isValidChanged = (value, selected, prop) => {
    if (!selected) {
        return value !== undefined
    }

    return selected[prop] !== value
}

export const isValidChangedProgramProject = (value, selected, prop) => {
    if (!selected) {
        return false
    }
    if (prop === 'is_op_goal') {
        return selected[prop].toLowerCase() !== value.toString()
    }
    if (prop === 'is_active') {
        if (!(prop in selected)) {
            return 'true' !== value.toString()
        }
        return selected[prop].toLowerCase() !== value.toString()
    }

    return selected[prop] !== value
}

export const addBadgesToList = (
    attributeName: string,
    list,
    newList,
    color: BADGE_COLOR,
    badgeName: BADGE_NAME,
) => {
    const result = [...list]
    const tempNewList = [...newList]
    result.forEach(function (item: any) {
        const index = tempNewList.findIndex(
            (newItem) => newItem[attributeName] === item[attributeName],
        )
        if (index !== -1) {
            item['badge'] = badgeName
            tempNewList.slice(index, 1)
        } else {
            item['badge'] = ''
        }
    })
    return result
}

export const isProgramProjectActiveForYear = (item) => {
    if (item.is_program === 'True' && item.is_core_program === 'True') {
        // core programs don't have active status
        return true
    }
    return !('is_active_for_year' in item) || item.is_active_for_year === 'True'
}

export const isProgramProjectActive = (item) => {
    if (item.is_program === 'True' && item.is_core_program === 'True') {
        // core programs don't have active status
        return true
    }
    return !('is_active' in item) || item.is_active === 'True'
}

export const formatYearSelection = (years) => {
    return years
        .map((year) => ({
            label: year,
            value: year,
        }))
        .sort((a, b) => b.label - a.label)
}

export const getFalconGroups = (allGroups: any[]) => {
    return (allGroups || []).filter((gp) => gp?.is_falcon)
}

export const filterActiveGroupsTeams = (allItems: any[]) => {
    return (allItems || []).filter((item) => item.is_active)
}

export const styleHeaderWithNoWrap = (headerText) => {
    return <p style={{ whiteSpace: 'nowrap' }}>{headerText}</p>
}

export const styleHeaderConditionally = (attrDef) => {
    const content = attrDef?.headerDisplay ? attrDef.headerDisplay : attrDef.headerName
    return attrDef?.tableVisible || attrDef?.summaryVisible
        ? styleHeaderWithNoWrap(content)
        : content
}

export const sortObjectsByField = (objectList: any[], fieldName: string) => {
    return objectList.sort((obj1, obj2) => (obj1[fieldName] < obj2[fieldName] ? -1 : 1))
}

export const parseYearFromDateString = (dateString: string | null) => {
    return dateString && dateString.length >= 4 ? dateString.substring(0, 4) : null
}
